import React, { useState, useRef, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import { Box, Typography } from "@mui/material";
import Pusher from "pusher-js";
import { dashboardSelector } from "../redux/reducers/dashboard";
import { manageRosterSelector, setRosterVehicle} from "../redux/reducers/manage_roster";
import { ATSSStorage, ATSSStorageKeys } from "../utils/atss_storage";
import { pusherApiKey } from "../utils/pusher_config";
import { shallowEqual, useSelector, useDispatch } from "react-redux";

// Individual Snackbar Component
const SnackbarMessage = ({ message, onClose }) => (
  <Snackbar
    open={true}
    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    sx={{ position: "relative", mt: 0 }}
  >
    <Alert
      severity={message.type}
      icon={<InfoIcon fontSize="large" />}
      sx={{
        px: 1,
        "& .MuiAlert-message": { pt: 0.5, pb: 0 },
        "& .MuiAlert-action": { pl: 1 },
      }}
      action={
        <IconButton
          size="inherit"
          aria-label="close"
          color="inherit"
          onClick={() => onClose(message.id)}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }
    >
      <Typography variant="h6">{message.message}</Typography>
      <Typography
        variant="overline"
        gutterBottom
        sx={{ display: "block", mb: 0, textAlign: "right", lineHeight: 1.3 }}
      >
        {message.timestamp}
      </Typography>
    </Alert>
  </Snackbar>
);

const SnackbarContainer = () => {
  const storage = new ATSSStorage();
  const dispatch = useDispatch();
  const { batchingPlant } = useSelector(
    dashboardSelector(["batchingPlant"]),
    shallowEqual
  );
  const { rosteredVehicle } = useSelector(
    manageRosterSelector(['rosteredVehicle']),
    shallowEqual
  );
  const [messages, setMessages] = useState([]);
  const pusherRef = useRef(null);
  const channelRef = useRef(null);
  const MAX_NOTIFICATIONS = 10;

  const addMessage = (message) => {
    const newMessage = {
      ...message,
      id: Math.random().toString(36).substr(2, 9),
      timestamp: formatTime(message.timestamp),
    };
    setMessages((prev) => {
      const updatedMessages = [newMessage, ...prev]; // Add new message at the beginning
      return updatedMessages.slice(0, MAX_NOTIFICATIONS); // Keep only the first 10 messages
    });
  };

  const removeMessage = (id) => {
    setMessages((prev) => prev.filter((message) => message.id !== id));
  };

  const formatTime = (timestamp) => {
    if (!timestamp) return "";
    const date = new Date(timestamp);
    return date.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  // Initialize Pusher
  useEffect(() => {
    pusherRef.current = new Pusher(pusherApiKey, {
      cluster: "ap1",
    });

    return () => {
      if (channelRef.current) {
        channelRef.current.unbind_all();
        channelRef.current.unsubscribe();
        channelRef.current = null;
      }
      if (pusherRef.current) {
        pusherRef.current.disconnect();
        pusherRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (batchingPlant) {
      const ZoneID =
        batchingPlant.ZoneID ??
        storage.getSessionItem(ATSSStorageKeys.dashboardBatchingPlant)?.ZoneID;
      if (ZoneID) {
        if (channelRef.current) {
          channelRef.current.unbind_all();
          channelRef.current.unsubscribe();
          channelRef.current = null;
        }

        const notificationsChannel = pusherRef.current.subscribe(
          `vehicle-queue-notifications-${ZoneID}`
        );
        channelRef.current = notificationsChannel;

        notificationsChannel.bind("notifications-updated", ({ message, timestamp }) => {

          if (rosteredVehicle) {
            if (message.includes("auto")) {
              dispatch(setRosterVehicle({ ...rosteredVehicle, manual_ranking: false }));
            } else if (message.includes("manual")) {
              dispatch(setRosterVehicle({ ...rosteredVehicle, manual_ranking: true }));
            }
          }
        
          addMessage({
            message,
            type: "info",
            timestamp: timestamp ?? Date.now(),
          });
        });
      }
    }
  }, [batchingPlant, rosteredVehicle]);

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 24,
        right: 5,
        zIndex: 2000,
        display: "flex",
        flexDirection: "column-reverse",
        gap: 1,
      }}
    >
      {messages.map((message) => (
        <SnackbarMessage
          key={message.id}
          message={message}
          onClose={removeMessage}
        />
      ))}
    </Box>
  );
};

export default SnackbarContainer;
